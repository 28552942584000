import { collection, doc, onSnapshot, query, where } from "firebase/firestore";
import { firestoreDB } from "ftp-firebase/firebaseConfig";
import { FC, lazy, Suspense, useEffect, useState } from "react";
import { Navigate, Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import ConnectWalletModal from "user/components/modals/ConnectWalletModal";
import { getNFTOwners, getNFTTransfers } from "ftp-firebase/api";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { RootState } from "app/store";
import { setCampaigns, setCurrencyExchangeRates, setNFTOwners, setNFTTransfers } from "app/features/rft/rftSlice";
import { setAvailabeLanguages, setLanguageTranslations } from "app/features/language/languageSlice";
import { setUsers } from "app/features/auth/authSlice";
import { getTokenId, removeLeadingZeros } from "core/helpers";
import PageLoader from "core/components/PageLoader";

const LazyAdminRoutes = lazy(() => import("admin/routes/AdminRoutes"));
const LazyUserRoutes = lazy(() => import("user/routes/UserRoutes"));
const LazyDocsRoutes = lazy(() => import("docs/routes/DocsRoutes"));

const LazyRFTStory = lazy(() => import("user/pages/RFTStory"));
const LazyHome = lazy(() => import("user/pages/HomeMap"));
const LazyNotFound = lazy(() => import("core/components/NotFound"));
const LazyChatGPT = lazy(() => import("user/pages/ChatGPT"));

const App: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const { languageTranslations } = useAppSelector((state: RootState) => state.languageReducer);
  const { openConnectWalletModal } = useAppSelector((state: RootState) => state.toggleReducer);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const localsDocRef = doc(firestoreDB, "translations", "locales");
    const localesSubscriber = onSnapshot(localsDocRef, (snapshot) => {
      const data: LanguageTranslationsTypes = {
        en: snapshot?.data()?.en,
        de: snapshot?.data()?.de,
        es: snapshot?.data()?.es,
        zh: snapshot?.data()?.zh,
      };
      dispatch(setLanguageTranslations(data));
    });

    const listDocRef = doc(firestoreDB, "translations", "languages_list");
    const listSubscriber = onSnapshot(listDocRef, (snapshot) => {
      const data: AvailableLanguageTypes[] = snapshot?.data()?.list.map((lang: AvailableLanguageTypes) => ({
        type: lang.type,
        title: lang.title,
        available: lang.available,
      }));
      dispatch(setAvailabeLanguages(data));
    });

    return () => {
      localesSubscriber();
      listSubscriber();
    };
  }, []);

  const getNFTData = async () => {
    try {
      setLoading(true);

      const nftTransfers: NFTTransfersType[] = (await getNFTTransfers()).docs.map((transfer) => ({
        block_timestamp: transfer.data().block_timestamp,
        from_address: transfer.data().from_address,
        to_address: transfer.data().to_address,
        token_id: transfer.data().token_id,
        token_title: transfer.data().token_title,
        smart_contract_address: transfer.data().smart_contract_address,
        transaction_hash: transfer.data().transaction_hash,
      }));

      const nftData: NFTItemType[] = (await getNFTOwners()).docs.map((nft) => ({
        center_coordinates_latitude: nft.data().center_coordinates_latitude,
        center_coordinates_longitude: nft.data().center_coordinates_longitude,
        co2_capturing: nft.data().co2_capturing,
        co2_storage: nft.data().co2_storage,
        corner_coordinates: nft.data().corner_coordinates,
        country_english: nft.data().country_english,
        ecoregion_1: nft.data().ecoregion_1,
        ecoregion_2: nft.data().ecoregion_2,
        edition: nft.data().edition,
        freshwater_production: nft.data().freshwater_production,
        owner_of: nft.data().owner_of,
        parcel_size: nft.data().parcel_size,
        protection_until_year: nft.data().protection_until_year,
        rft_image_color: nft.data().rft_image_color,
        rft_image_color_rarity: nft.data().rft_image_color_rarity,
        rft_statement: nft.data().rft_statement,
        rft_statement_rarity: nft.data().rft_statement_rarity,
        territory_english: nft.data().territory_english,
        territory_native: nft.data().territory_native,
        token_id: nft.data().token_id,
        token_title: nft.data().token_title,
        token_hash: nft.data().token_hash,
        image: nft.data().image,
        purchase_date: nft.data().purchase_date,
        price: nft.data().price,
        campaign_name: nft.data().campaign_name,
        claimed: nft.data().claimed,
      }));

      const genesisEdition = nftData
        .filter((nft) => nft.token_title.includes("#001-"))
        .sort((a, b) => Number(a.token_id) - Number(b.token_id));

      const secondEdition = nftData
        .filter((nft) => nft.token_title.includes("#002-"))
        .sort(
          (a, b) =>
            Number(removeLeadingZeros(getTokenId(a.token_id))) - Number(removeLeadingZeros(getTokenId(b.token_id)))
        );

      const specialEditions = nftData
        .filter((nft) => nft.token_title.includes("#s"))
        .sort((a, b) => Number(a.token_id) - Number(b.token_id));

      const conf3renceEdition = nftData
        .filter((nft) => nft.token_title.toLowerCase().includes("conf3rence"))
        .sort((a, b) => Number(a.token_id) - Number(b.token_id));

      const followerCampaignEdition = nftData.filter((nft) => nft.token_title.toLowerCase().includes("100k"));

      const allEditions = [
        ...new Set([
          ...genesisEdition,
          ...secondEdition,
          ...specialEditions,
          ...conf3renceEdition,
          ...followerCampaignEdition,
        ]),
      ];

      dispatch(setNFTTransfers(nftTransfers));
      dispatch(setNFTOwners(allEditions));

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getNFTData();
  }, []);

  useEffect(() => {
    const usersSubscriber = onSnapshot(collection(firestoreDB, "users"), (snapshot) => {
      const userData: UserTypes[] = snapshot.docs.map((user) => {
        return {
          id: user.id,
          userName: user.data().userName,
          email: user.data().email,
          password: user.data().password,
          bio: user.data().bio,
          twitterLink: user.data().twitterLink,
          instagramLink: user.data().instagramLink,
          linkedInLink: user.data().linkedInLink,
          discordLink: user.data().discordLink,
          websiteLink: user.data().websiteLink,
          walletAddress: user.data().walletAddress,
          profileImage: user.data().profileImage,
          profileImageHash: user.data().profileImageHash,
          md5EncodedName: user.data().md5EncodedName,
        };
      });

      dispatch(setUsers(userData));
    });

    const currencySubscriber = onSnapshot(collection(firestoreDB, "exchangeRates"), (snapshot) => {
      const data: ExchangeRatesTypes = {
        daiExchangeRates: snapshot.docs[0]?.data().daiExchangeRates,
        ethExchangeRates: snapshot.docs[0]?.data().ethExchangeRates,
        usdExchangeRates: snapshot.docs[0]?.data().usdExchangeRates,
        usdcExchangeRates: snapshot.docs[0]?.data().usdcExchangeRates,
      };
      dispatch(setCurrencyExchangeRates(data));
    });

    const nftOwnersSubscriber = onSnapshot(collection(firestoreDB, "nftOwners"), (snapshot) => {
      const nftData: NFTItemType[] = snapshot.docs.map((nft) => {
        return {
          center_coordinates_latitude: nft.data().center_coordinates_latitude,
          center_coordinates_longitude: nft.data().center_coordinates_longitude,
          co2_capturing: nft.data().co2_capturing,
          co2_storage: nft.data().co2_storage,
          corner_coordinates: nft.data().corner_coordinates,
          country_english: nft.data().country_english,
          ecoregion_1: nft.data().ecoregion_1,
          ecoregion_2: nft.data().ecoregion_2,
          edition: nft.data().edition,
          freshwater_production: nft.data().freshwater_production,
          owner_of: nft.data().owner_of,
          parcel_size: nft.data().parcel_size,
          protection_until_year: nft.data().protection_until_year,
          rft_image_color: nft.data().rft_image_color,
          rft_image_color_rarity: nft.data().rft_image_color_rarity,
          rft_statement: nft.data().rft_statement,
          rft_statement_rarity: nft.data().rft_statement_rarity,
          territory_english: nft.data().territory_english,
          territory_native: nft.data().territory_native,
          token_id: nft.data().token_id,
          token_title: nft.data().token_title,
          token_hash: nft.data().token_hash,
          image: nft.data().image,
          purchase_date: nft.data().purchase_date,
          price: nft.data().price,
          campaign_name: nft.data().campaign_name,
          claimed: nft.data().claimed,
        };
      });

      const genesisEdition = nftData
        .filter((nft) => nft.token_title.includes("#001-"))
        .sort((a, b) => Number(a.token_id) - Number(b.token_id));

      const secondEdition = nftData
        .filter((nft) => nft.token_title.includes("#002-"))
        .sort(
          (a, b) =>
            Number(removeLeadingZeros(getTokenId(a.token_id))) - Number(removeLeadingZeros(getTokenId(b.token_id)))
        );

      const specialEditions = nftData
        .filter((nft) => nft.token_title.includes("#s"))
        .sort((a, b) => Number(a.token_id) - Number(b.token_id));

      const conf3renceEdition = nftData
        .filter((nft) => nft.token_title.toLowerCase().includes("conf3rence"))
        .sort((a, b) => Number(a.token_id) - Number(b.token_id));

      const followerCampaignEdition = nftData.filter((nft) => nft.token_title.toLowerCase().includes("100k"));

      const allEditions = [
        ...new Set([
          ...genesisEdition,
          ...secondEdition,
          ...specialEditions,
          ...conf3renceEdition,
          ...followerCampaignEdition,
        ]),
      ];

      dispatch(setNFTOwners(allEditions));
    });

    const campaignsRef = collection(firestoreDB, "campaigns");
    const campaignsQuery = query(campaignsRef, where("status", "==", "Live"));

    const campaignsSubscriber = onSnapshot(campaignsQuery, (snapshot) => {
      const data: CampaignTypes[] = snapshot.docs.map((campaign) => ({
        id: campaign.id,
        campaignName: campaign.data().campaignName,
        initiatorName: campaign.data().initiatorName,
        campaignMessage: campaign.data().campaignMessage,
        campaignImage: campaign.data().campaignImage,
        campaignImageHash: campaign.data().campaignImageHash,
      }));

      dispatch(setCampaigns(data));
    });

    return () => {
      usersSubscriber();
      currencySubscriber();
      nftOwnersSubscriber();
      campaignsSubscriber();
    };
  }, []);

  if (!languageTranslations) {
    return <PageLoader />;
  }

  if (loading) {
    return <PageLoader />;
  }

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/">
          <Route index element={<LazyHome />} />
          <Route path="rft/:token_id" element={<LazyHome />} />
          <Route path=":name_wallet_address" element={<LazyHome />} />
          <Route path="landmark/:marker_title" element={<LazyHome />} />
          <Route path="intro" element={<LazyRFTStory />} />
          <Route path="not-found" element={<LazyNotFound />} />
          <Route path="chat" element={<LazyChatGPT />} />
          <Route path="002/claim-your-rft" element={<LazyHome />} />
          <Route path="campaign/:campaign_name" element={<LazyHome />} />
          <Route path="login" element={<LazyHome />} />
        </Route>
        {/* admin routes */}
        <Route path="admin/*" element={<LazyAdminRoutes />} />

        {/* user routes */}
        <Route path="user/*" element={<LazyUserRoutes />} />

        {/* docs routes */}
        <Route path="docs/*" element={<LazyDocsRoutes />} />

        {/* 404 fallback */}
        <Route path="/*" element={<Navigate to="/not-found" replace={true} />} />
      </>
    )
  );

  return (
    <Suspense fallback={<PageLoader />}>
      {openConnectWalletModal ? <ConnectWalletModal /> : null}
      <RouterProvider router={router} />
    </Suspense>
  );
};

export default App;
