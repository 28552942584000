import { setUserDetails } from "app/features/auth/authSlice";
import {
  setOpenConnectWalletModal,
  setOpenLoginWithEmailModal,
  setOpenSignUpWithEmailModal,
  setOpenUserSidebar,
} from "app/features/toggle/toggleSlice";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { RootState } from "app/store";
import axios from "axios";
import Button from "core/components/Button";
import Loader from "core/components/Loader";
import Modal from "core/components/Modal";
import { DocumentData } from "firebase/firestore";
import { loginUserWithWalletAddress, logOutUser } from "ftp-firebase/api";
import { FC, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { translate } from "user/functions/translate";
import CoinBaseIcon from "user/icons/CoinBaseIcon";
import MetaMaskIcon from "user/icons/MetaMaskIcon";
import WalletConnectIcon from "user/icons/WalletConnectIcon";
import {
  useAccount,
  useConnect,
  useDisconnect,
  useSignMessage,
  ProviderNotFoundError,
  Connector,
  useChainId,
} from "wagmi";

import { SignMessageData } from "wagmi/query";
import { type ConnectReturnType, type ConnectErrorType } from "@wagmi/core";

const ConnectWalletModal: FC = () => {
  const { users, userDetails, adminDetails } = useAppSelector((state: RootState) => state.authReducer);
  const { openConnectWalletModal } = useAppSelector((state: RootState) => state.toggleReducer);
  const { currentLanguage, languageTranslations } = useAppSelector((state: RootState) => state.languageReducer);

  const dispatch = useAppDispatch();

  const [showLoginORSignUpWithWallets, setShowLoginORSignUpWithWallets] = useState<boolean>(false);
  const [walletLoading, setWalletLoading] = useState<boolean>(false);

  const { disconnect } = useDisconnect();
  const { isDisconnected, address } = useAccount();
  const chainId = useChainId();

  const { signMessage } = useSignMessage({
    mutation: {
      onSuccess: async (sign: SignMessageData) => {
        const response = await axios.post(process.env.REACT_APP_VERIFY_SIGNED_MESSAGE_API_URL, {
          address: address.toLowerCase(),
          signature: sign,
        });

        if (response.status === 200) {
          const result = await loginUserWithWalletAddress(response.data.token);
          if (result.user) {
            const successMessage = translate(
              "wallet_connected_successfully_message",
              currentLanguage,
              languageTranslations
            );
            toast.success(successMessage);
            const data = users.find((user: DocumentData) => user.id.toLowerCase() === address.toLowerCase());
            const userData: UserTypes = {
              id: result.user.uid,
              userName: data.userName,
              md5EncodedName: data.md5EncodedName,
              email: data.email,
              password: data.password,
              bio: data.bio,
              twitterLink: data.twitterLink,
              linkedInLink: data.linkedInLink,
              discordLink: data.discordLink,
              instagramLink: data.instagramLink,
              websiteLink: data.websiteLink,
              profileImage: data.profileImage,
              profileImageHash: data.profileImageHash,
              walletAddress: data.walletAddress,
              loginTime: new Date().toISOString(),
            };

            dispatch(setUserDetails(userData));
            setWalletLoading(false);
            closeModal();
          }
        }
      },
      onError: (error) => {
        disconnect();
        closeModal();
        dispatch(setOpenUserSidebar(false));
        dispatch(setUserDetails(null));
        setWalletLoading(false);
        toast.error(translate("wallet_already_connect_error_message", currentLanguage, languageTranslations));
      },
    },
  });

  const { connect, connectors } = useConnect({
    mutation: {
      onSuccess: async (result: ConnectReturnType) => {
        if (result.accounts[0]) {
          setWalletLoading(true);
          const response = await axios.post(process.env.REACT_APP_GET_NONCE_API_URL, {
            address: result.accounts[0].toLowerCase(),
          });

          if (response.status === 200) {
            signMessage({ account: result.accounts[0], message: response.data.nonce });
          }
        }
      },
      onError: (error: ConnectErrorType) => {
        if (error.name === "ConnectorAlreadyConnectedError") {
          disconnect();
          closeModal();
          dispatch(setOpenUserSidebar(false));
          dispatch(setUserDetails(null));
          setWalletLoading(false);
          toast.error(translate("wallet_already_connect_error_message", currentLanguage, languageTranslations));
        }

        if (error.name === ProviderNotFoundError.name) {
          toast.error(translate("meta_mask_not_installed_error_message", currentLanguage, languageTranslations), {
            autoClose: 6000,
          });
          closeModal();
          setWalletLoading(false);
        }
      },
    },
  });

  useEffect(() => {
    if (userDetails?.email) return;
    if (adminDetails) return;

    if (isDisconnected) {
      dispatch(setOpenUserSidebar(false));
      dispatch(setUserDetails(null));
      (async () => {
        try {
          await logOutUser();
        } catch (error) {
          console.error(error);
        }
      })();
    }
  }, [isDisconnected, userDetails?.email, adminDetails]);

  const handleConnectWallet = (connector: Connector) => {
    if (connector.id === "walletConnect") {
      closeModal();
      setWalletLoading(false);
    }

    connect({ connector, chainId });
  };

  const renderLoginORSignUpWithWallets = () =>
    walletLoading ? (
      <Loader height="h-20" width="w-20" className="my-5" />
    ) : (
      <div className="mt-2">
        <p className="text-darkCharcoal/80">
          {languageTranslations
            ? translate("connect_wallet_modal_description", currentLanguage, languageTranslations)
            : ""}
        </p>

        {/* wallets */}
        <div className="my-4 font-londrinaSolid text-sm sm:text-base">
          {connectors.map((connector) => (
            <Button
              key={connector.uid}
              onClick={() => handleConnectWallet(connector)}
              className={`group w-full ${
                connector.name === "MetaMask" ? "rounded-t-lg" : connector.name === "WalletConnect" && "rounded-b-lg"
              } border p-3 hover:shadow-md transition-none tracking-wider`}
            >
              {connector.name === "MetaMask" ? (
                <>
                  <MetaMaskIcon className="h-4" />
                  <span className="ml-3 flex-1 text-left">{connector.name}</span>
                  <span className="ml-3 inline-flex items-center justify-center rounded bg-gray-200 px-2 py-0.5 text-xs font-light text-gray-500">
                    {languageTranslations
                      ? translate("connect_wallet_modal_popular", currentLanguage, languageTranslations)
                      : ""}
                  </span>
                </>
              ) : connector.name === "Coinbase Wallet" ? (
                <>
                  <CoinBaseIcon className="h-5" />
                  <span className="ml-3 flex-1 text-left">{connector.name}</span>
                </>
              ) : (
                <>
                  <WalletConnectIcon className="h-5" />
                  <span className="ml-3 flex-1 text-left">{connector.name}</span>
                </>
              )}
            </Button>
          ))}

          {/* <button className="group flex w-full items-center rounded-b-lg border p-3 text-F14 text-darkGreen hover:scale-100 hover:shadow-md">
        <FortmaticIcon className="h-4" />
        <span className="ml-3 flex-1 text-left font-nunito font-bold">Fortmatic</span>
      </button> */}
        </div>
      </div>
    );

  const closeModal = () => {
    dispatch(setOpenConnectWalletModal(false));
    window.history.replaceState("", "", "/");
    setTimeout(() => {
      setShowLoginORSignUpWithWallets(false);
    }, 500);
  };

  const onLoginWithEmailClick = () => {
    dispatch(setOpenLoginWithEmailModal(true));
    setShowLoginORSignUpWithWallets(false);
    dispatch(setOpenConnectWalletModal(false));
    dispatch(setOpenSignUpWithEmailModal(false));
  };

  const onLoginORSignupWithWalletsClick = () => {
    dispatch(setOpenLoginWithEmailModal(false));
    setShowLoginORSignUpWithWallets(true);
    dispatch(setOpenSignUpWithEmailModal(false));
  };

  const handleDiscoverWithoutLogin = () => {
    dispatch(setOpenConnectWalletModal(false));
  };

  return (
    <Modal
      showModal={openConnectWalletModal}
      title={
        showLoginORSignUpWithWallets
          ? languageTranslations
            ? translate("connect_modal_with_wallets_title", currentLanguage, languageTranslations)
            : ""
          : translate("connect_modal_title", currentLanguage, languageTranslations)
      }
      closeButtonClick={closeModal}
      modalStyle="max-w-lg"
      disableCloseButton={walletLoading}
    >
      {showLoginORSignUpWithWallets ? (
        renderLoginORSignUpWithWallets()
      ) : (
        <div className="mt-4 flex w-full flex-col items-center justify-center space-y-4 font-londrinaSolid font-normal text-sm text-white md:text-base">
          <Button
            onClick={onLoginORSignupWithWalletsClick}
            className="w-full rounded-full tracking-wider bg-mediumGreen py-2 hover:bg-green5"
          >
            {translate("connect_wallet_button", currentLanguage, languageTranslations)}
          </Button>

          <Button
            onClick={onLoginWithEmailClick}
            className="w-full rounded-full tracking-wider bg-mediumGreen py-2 hover:bg-green5"
          >
            {translate("login_sign_up_with_email_button", currentLanguage, languageTranslations)}
          </Button>

          <Button
            onClick={handleDiscoverWithoutLogin}
            className="w-full rounded-full tracking-wider text-mediumGreen border-2 border-mediumGreen py-2 bg-white"
          >
            {translate("discover_without_login_button", currentLanguage, languageTranslations)}
          </Button>
        </div>
      )}
    </Modal>
  );
};

export default ConnectWalletModal;
