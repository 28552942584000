import { mainnet, polygon } from "viem/chains";
import { createConfig, http } from "wagmi";
import { coinbaseWallet, walletConnect, metaMask } from "wagmi/connectors";

const config = createConfig({
  chains: [mainnet, polygon],
  transports: {
    [mainnet.id]: http(),
    [polygon.id]: http(),
  },
  connectors: [
    metaMask(),
    coinbaseWallet({ appName: "Fund The Planet" }),
    walletConnect({ projectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID }),
  ],
});

export default config;
