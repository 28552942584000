import React from "react";
import { createRoot } from "react-dom/client";
import App from "App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { persistor, store } from "app/store";
import { PersistGate } from "redux-persist/integration/react";

import "index.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import "mapbox-gl/dist/mapbox-gl.css";
import "react-toastify/dist/ReactToastify.css";
import "react-spring-bottom-sheet/dist/style.css";
import "react-lazy-load-image-component/src/effects/blur.css";

import { Slide, ToastContainer } from "react-toastify";

import { WagmiProvider } from "wagmi";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import config from "core/walletConfig";

const queryClient = new QueryClient();

createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <WagmiProvider config={config}>
          <QueryClientProvider client={queryClient}>
            <App />
            <ToastContainer
              position="bottom-center"
              transition={Slide}
              autoClose={3000}
              bodyClassName={() => "text-sm flex flex-row font-white font-plusJakarta p-3"}
            />
          </QueryClientProvider>
        </WagmiProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
